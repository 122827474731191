<template>
    <div>
        <div v-if="authUserPermission['stock-calc-fast-by-demand']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-12">
															<h3>Rekomendasi Stok Sparepart Optimum for Single Material Code</h3>
															<el-dialog
																title="Matrix Perhitungan"
																:visible.sync="matrixDialogVisible"
																width="70%"
																:close-on-click-modal="false"
																@close="handleDialogClose"
															>
																<div class="table-container">
																	<table class="matrix-table">
																		<thead>
																			<tr>
																				<th rowspan="4" class="variable-header" style="width: 180px">Variable</th>
																				<th colspan="8" class="method-header">METODE</th>
																			</tr>
																			<tr>
																				<th colspan="3" class="sub-header">N=0</th>
																				<th class="sub-header">N &lt; 30</th>
																				<th class="sub-header">N &gt;= 30</th>
																				<th colspan="3" class="sub-header">N &lt; 30 &amp; N &gt; 30</th>
																			</tr>
																			<tr>
																				<th colspan="3" class="sub-header" style="background-color: #1579ab"> NON MOVING (PT) </th>
																				<th class="sub-header" style="background-color: #1579ab; font-weight: bold;"> Poisson (PP) </th>
																				<th class="sub-header" style="background-color: #1579ab; font-weight: bold;; width: 60px"> Normal (PN) </th>
																				<th class="sub-header" style="background-color: #1579ab; font-weight: bold;"> Deterministik (MD) </th>
																				<th class="sub-header" colspan="2" style="background-color: #1579ab; font-weight: bold;"> Tak Tentu (MT) </th>
																			</tr>
																			<tr>
																				<th class="sub-header" style="width: 70px; font-weight: bold;">Regret</th>
																				<th class="sub-header" style="width: 100px; font-weight: bold;">Liniear Kerusakan</th>
																				<th class="sub-header" style="width: 120px; font-weight: bold;"> Non Linear Kerusakan </th>
																				<th class="sub-header" style="width: 75px; font-weight: bold;">Poisson</th>
																				<th class="sub-header" style="width: 75px; font-weight: bold;">Model Q</th>
																				<th class="sub-header" style="width: 110px; font-weight: bold;">Deterministik</th>
																				<th class="sub-header" style="width: 80px; font-weight: bold;">Tchebycheff</th>
																				<th class="sub-header" style="width: 80px; font-weight: bold;">MinMax</th>
																			</tr>
																			<tr>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> Output </th>  
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, TCO </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, TCO </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, TCO </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, ROP, SS </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, ROP, SS, Service Level, TCO </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, ROP, TCO </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> EOQ, TCO </th>
																				<th class="sub-header text-dark value-table-wrapped" style="background-color: #1ad0b7; font-weight: bold;"> SS, ROP, EOQ, TCO </th>
																			</tr>
																		</thead>
																		<tbody class="text-dark" style="font-weight: bold;">
																			<tr>
																				<td>*Demand (D)</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																			</tr>
																			<tr>
																				<td>*Lead Time (L)</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td></td>
																				<td>✓</td>
																			</tr>
																			<tr>
																				<td>*Unit Price (p)</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																			</tr>
																			<tr>
																				<td>**Stock Effect (Cu)</td>
																				<td></td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td></td>
																				<td>✓</td>
																				<td></td>
																			</tr>
																			<tr>
																				<td>**Order Cost (A)</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td></td>
																				<td>✓</td>
																			</tr>
																			<tr>
																				<td>**Holding Cost (h)</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																			</tr>
																			<tr>
																				<td>**Suku Bunga (i)</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																			<tr>
																				<td>**Jumlah Komponen Terpasang (n)</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td>✓</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																			<tr>
																				<td>***Service Level</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td>✓</td>
                                				<td>✓</td>
																				<td></td>
																				<td></td>
																				<td>✓</td>
																			</tr>
																			<tr>
																				<td>Sisa Tahun Pemakaian</td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																				<td></td>
																			</tr>
																		</tbody>
																	</table>
																	<h5>
																		Keterangan: <br> N merupakan jumlah adanya pengambilan sejak tahun 2018 dalam satuan waktu bulan <br> *Data terisi otomatis dari SAP (editable) <br> **Data menggunakan fixed asumsi bersifat prefilled (editable) <br> ***Data merupakan output perhitungan dan berdifat editable (recalculate) <br> 
																		<div>
																				<div>
																						<span class="text-white my-0 py-0" style="background-color: #1579ab">&nbsp;&nbsp;&nbsp;Metode Return Value From API&nbsp;&nbsp;&nbsp;</span>
																				</div>
																				<div>
																						<span class="text-white my-0 py-0" style="background-color: #1ad0b7">&nbsp;&nbsp;&nbsp;Output Masing-Masing Metode&nbsp;&nbsp;&nbsp;</span>
																				</div>
																		</div>
																	</h5>
																</div>
																<span slot="footer" class="dialog-footer">
																	<el-button @click="matrixDialogVisible = false">Tutup</el-button>
																</span>
															</el-dialog>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="col-16 mt-3">
                            <div class="d-flex flex-wrap align-items-start">
                                <div class="mr-2" style="flex: 0 0 145px; min-width: 100px;">
                                    <label class="form-control-label">
                                        <b>Gunakan Data TA </b>
                                        <el-tooltip placement="top">
                                            <template #content> Pilihan Mengikutsertakan Data Demand <br> Turn Around pada Perhitungan </template>
																						<sup>
                                                <span style="font-size:10px"><i
																									class="fa fa-exclamation-circle"></i></span>
                                            </sup>
                                        </el-tooltip>
                                    </label>
                                    <base-input>
                                        <el-select v-model="dataTa" style="width: 100%; margin-top: 5px;" placeholder="Choose" :disabled="loadingCalculate">
                                            <el-option label="Ya" value="true"></el-option>
                                            <el-option label="Tidak" value="false"></el-option>
                                        </el-select>
                                    </base-input>
                                </div>
                                <div class="mr-2" style="flex: 0 0 145px; min-width: 100px;">
                                    <label class="form-control-label">
                                        <b>Anak Perusahaan </b>
                                        <el-tooltip placement="top">
                                            <template #content> Anak Perusahaan dalam PI Group </template>
                                            <sup>
                                                <span style="font-size:10px">
                                                    <i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </label>
                                    <base-input>
                                        <el-select v-model="dataFitting.plant" filterable style="width: 100%; margin-top: 5px;" placeholder="Pilih Plant" :disabled="loadingCalculate  || ['B000', 'C000', 'D000', 'E000', 'F000', 'G000', 'H000', 'I000', 'J000'].includes(authUser.company_code)" @change="changePlant">
                                            <el-option v-for="plant in plants" :key="plant.code" :value="plant.code"
                                                :label="`${plant.name}`"></el-option>
                                        </el-select>
                                    </base-input>
                                </div>
                                <div class="mr-2" style="flex: 0 0 170px; min-width: 250px;">
                                    <label class="form-control-label">
                                        <b>Material Number </b>
                                        <el-tooltip placement="top">
                                            <template #content>Material Spare Part MRO (6XXXXX)</template>
                                            <sup>
                                                <span style="font-size:10px">
                                                    <i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </label>
                                    <base-input>
                                        <el-autocomplete v-model="searchQuery" style="width: 100%; margin-top: 5px;" :fetch-suggestions="fetchSuggestionsMasterMaterialNumber" placeholder="Search Material Number" :loading="loadingSearch" @select="handleSelectMaterialNumber" :trigger-on-focus="false">
                                            <template slot-scope="{ item }">
                                                <div :class=" item.label === 'No Data' ? 'text-center' : '' ">{{ item.label }}</div>
                                            </template>
                                        </el-autocomplete>
                                    </base-input>
                                </div>
                                <div class="d-flex align-items-center" style="flex: 1; min-width: 200px; margin-top: 35px;">
                                    <base-button type="success" @click="handleFittingData" :disabled="isFittingDataDisabled" :loading="loadingFittingData">
                                        Fitting Data
                                    </base-button>

                                    <base-button type="danger" @click="resetParameter" :disabled="loadingDownload">
                                        Reset
                                        <el-tooltip placement="top">
                                            <template #content> Mereset ulang inputan </template>
                                            <sup>
                                                <span style="font-size:10px"><i class="fa fa-exclamation-circle"></i>
                                                </span>
                                            </sup>
                                        </el-tooltip>
                                    </base-button>

                                    <base-button type="warning" @click="openMatrixDialog">
                                        Matrix Perhitungan<i class="fas fa-chart-line"></i>
                                        <el-tooltip placement="top">
                                            <template #content> Memunculkan matrix kebutuhan data untuk <br> tipe material data</template>
                                            <sup><span style="font-size:10px"><i
                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                    </base-button>

                                </div>
                            </div>

                            <div class="table-responsive mt-1" v-if="openDemandRate && allMonthsData && allMonthsData.length > 0">
                                <table class="table table-bordered customTableInputKecil table-striped">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th v-for="(item, index) in allMonthsData" :key="'header-' + index">
                                                {{ item.monthYear }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle">
                                                Demand Request
                                                <el-tooltip placement="top">
                                                    <!-- <template #content>Demand request dari data 261 atau z61 dengan 
                                                        <br>time frame 5 tahun terakhir yang 
                                                        <br>diagregasikan per kebutuhan / hari</template> -->
																										<template #content>Demand request dalam 12 bulan untuk simulasi grafik</template>
                                                    <sup>
																											<span style="font-size:10px">
																												<i class="fa fa-exclamation-circle"></i>
																											</span>
																										</sup>
                                                </el-tooltip>
                                            </td>
                                            <td v-for="(item, index) in allMonthsData" :key="'request-' + index">
                                                <el-tooltip :content="'Bulan: ' + item.monthYear" placement="top">
                                                    <input class="form-control" placeholder="0"
                                                        v-model.number="demandRequest[index]" @input="setEmptyReturnCalcAndDiagram()"
                                                        :disabled="loadingCalculate" type="number" />
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="align-middle">
                                                History Demand
                                                <el-tooltip placement="top">
                                                    <!-- <template #content>Demand request dari history <br>data pemakaian rutin ditampilkan per bulan</template> -->
																										<template #content>Demand request dari history data 12 bulan sebelumnya</template>
                                                    <sup>
																											<span style="font-size:10px">
																												<i class="fa fa-exclamation-circle"></i>
																											</span>
																										</sup>
                                                </el-tooltip>
                                            </td>
                                            <!-- Tampilkan history demand rate -->
                                            <td v-for="(item, index) in allMonthsData" :key="'demand-history-' + index">
                                                <input class="form-control" :placeholder="item.value"
                                                    :value="item.value" disabled type="number" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Kolom untuk Unit Price, Initial Inventory, Metode ROQ, dan Order Cost dengan latar belakang abu-abu -->
                            <div class="row mt-4" v-if="openTableDirekomendasikan">
                                <div class="col-12">
                                    <div class="p-3" style="background-color: #e3e3e3">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="form-control-label" style="font-size:0.85vw;">
                                                    <b>Direkomendasikan metode <el-tooltip placement="top">
                                                        <template #content> Rekomendasi metode untuk perhitungan <br> berdasarkan karakteristik data
                                                        </template>
                                                        <sup>
																													<span style="font-size:10px">
																														<i class="fa fa-exclamation-circle"></i>
																													</span>
																												</sup>
                                                    </el-tooltip></b>
																										
																										<span>&nbsp;{{ fittingDataResult.kategori}}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
																					<!-- Current Stock -->
																					<div class="col-md-3 mb-3">
																						<label class="form-control-label" style="font-size:0.85vw;">
																							<b> Initial Inventory </b>
																							<el-tooltip placement="top" effect="dark" >
																									<template #content>
																										Ketersediaan stok di gudang saat ini, <br> untuk simulasi grafik
																									</template>
																									<sup>
																											<span style="font-size:0.8vw;">
																													<i class="fa fa-exclamation-circle"></i>
																											</span>
																									</sup>
																							</el-tooltip>
																						</label>
																						<input class="form-control" style="width: 100%;" placeholder="Current Time" v-model.number="fittingDataResult.current_stock" type="number" :disabled="loadingCalculate" @input="setEmptyReturnCalcAndDiagram()">
																					</div>

																					<!-- Kolom Unit Price -->
																					<div class="col-md-3 mb-3" v-if="['Normal', 'Tak Tentu', 'Poisson', 'Deterministik', 'Non Moving'].includes(fittingDataResult.kategori)">
																							<label class="form-control-label" style="font-size:0.85vw;"><b>Unit Price </b>
																									<el-tooltip placement="top">
																											<template #content> Harga 1 UoM sparepart dalam satuan rupiah
																											</template>
																											<sup>
																												<span style="font-size:0.8vw;">
																													<i class="fa fa-exclamation-circle"></i>
																												</span>
																											</sup>
																									</el-tooltip>
																							</label>
																							<input class="form-control" style="width: 100%;" placeholder="Unit Price" v-model="formattedUnitPrice" type="text" :disabled="loadingCalculate">
																					</div>

																					<!-- Kolom Lead Time -->
																					<div class="col-md-3 mb-3" v-if="['Poisson', 'Normal', 'Tak Tentu', 'Deterministik'].includes(fittingDataResult.kategori)">
																						<label class="form-control-label" style="font-size:0.85vw;">
																								<b>Lead Time (Bulan) </b>
																								<el-tooltip placement="top" effect="dark">
																										<template #content>
																												Durasi waktu semenjak user (perencanaan pemeliharaan) <br>
																												menginformasikan permintaan Spare Part <br> sampai dengan
																												waktu kedatangannya (PR-GR).<br>
																												Range leadtime material {{ fittingDataResult.material }}:
																												<br><br>
																												Maksimum: {{ fittingDataResult.lead_time_max ? fittingDataResult.lead_time_max.toFixed(2) : 0
																												}}<br>
																												Rata-Rata: {{ fittingDataResult.lead_time ? fittingDataResult.lead_time.toFixed(2) : 0 }}<br>
																												Minimum: {{ fittingDataResult.lead_time_min ? fittingDataResult.lead_time_min.toFixed(2) : 0 }}
																										</template>
																										<sup>
																												<span style="font-size:0.8vw;">
																														<i class="fa fa-exclamation-circle"></i>
																												</span>
																										</sup>
																								</el-tooltip>
																						</label>
																						<input class="form-control" style="width: 100%;" placeholder="Lead Time" v-model.number="formattedLeadTime" type="number" :disabled="loadingCalculate" @input="setEmptyReturnCalcAndDiagram()">
																					</div>

																					<!-- Kolom Order Cost -->
																					<div class="col-md-3 mb-3" v-if="['Normal', 'Poisson', 'Deterministik', 'Tak Tentu'].includes(fittingDataResult.kategori)">
																						<label class="form-control-label" style="font-size:0.85vw;"><b>Order Cost </b>
																								<el-tooltip placement="top" >
																										<template #content>Biaya pemesanan setiap kali pesan, biaya pemesanan ini dapat mencakup biaya pengiriman, biaya transportasi tak terduga, biaya inspeksi, dan biaya lain yang diperlukan untuk memperoleh produk persediaan</template>
																										<sup><span style="font-size:0.8vw;"><i
																																class="fa fa-exclamation-circle"></i></span></sup>
																								</el-tooltip>
																						</label>
																						<input class="form-control" style="width: 100%;" placeholder="Order Cost" v-model="formattedOrderCost" type="text" :disabled="loadingCalculate">
																					</div>

																					<!-- Kolom Holding Cost (%) -->
																							<!-- v-if="['Normal', 'Poisson', 'Deterministik', 'Tak Tentu'].includes(fittingDataResult.kategori)"> -->
																					<div class="col-md-3 mb-3" v-if="['Normal', 'Poisson', 'Deterministik', 'Tak Tentu'].includes(fittingDataResult.kategori)">
																							<label class="form-control-label" style="font-size:0.85vw;"><b>Holding Cost (%) </b>
																									<el-tooltip placement="top">
																											<template #content> Biaya penyimpanan persediaan/Spare Part, <br> persentase dari unit price </template>
																											<sup><span style="font-size:0.8vw;"><i
																																	class="fa fa-exclamation-circle"></i></span></sup>
																									</el-tooltip>
																							</label>
																							<input class="form-control" placeholder="Holding Cost (%)" type="number" v-model.number="holdingCost" :disabled="loadingCalculate" @input="setEmptyReturnCalcAndDiagram()">
																					</div>

																					<!-- Kolom Stock Out Effect -->
																					<div class="col-md-3 mb-3" v-if="['Normal', 'Non Moving', 'Poisson', 'Tak Tentu'].includes(fittingDataResult.kategori)">
																							<label class="form-control-label" style="font-size:0.85vw;"><b>Stock Out Effect </b>
																									<el-tooltip placement="top">
																											<template #content> Ongkos kerugian akibat ketidaktersediaan
																													<br> sparepart per hari </template>
																											<sup><span style="font-size:0.8vw;"><i
																																	class="fa fa-exclamation-circle"></i></span></sup>
																									</el-tooltip>
																							</label>
																							<input class="form-control" style="width: 100%;" placeholder="Stock Out Effect" v-model="formattedStockOutEffect" type="text" :disabled="loadingCalculate">
																					</div>

																					<!-- Kolom Service Level -->
																					<div class="col-md-3 mb-3" v-if="['Normal', 'Poisson'].includes(fittingDataResult.kategori) || (fittingDataResult.kategori == 'Tak Tentu' && serviceLevel && afterFirstRunCalc)">
																							<label class="form-control-label" style="font-size:0.85vw;"><b>Service Level (%)</b>
																									<el-tooltip placement="top">
																											<template #content> Kemampuan memberikan pelayanan kepada user
																													<br> pemeliharaan yang diukur berdasarkan rasio antara <br>
																													tingkat ketersediaan (availability) Material <br> Stock
																													dengan frekuensi permintaan </template>
																											<sup><span style="font-size:0.8vw;"><i class="fa fa-exclamation-circle"></i></span></sup>
																									</el-tooltip>
																							</label>
																							<input class="form-control" style="width: 100%;" placeholder="Service Level (%)" v-model.number="serviceLevel" type="number" @input="setEmptyReturnCalcAndDiagram()" :disabled="!['Normal', 'Poisson', 'Tak Tentu'].includes(fittingDataResult.kategori)">
																									<!-- isServiceLevelDisabled ||  -->
																					</div>	

																					<!-- Kolom Komponen Terpasang -->
																					<div class="col-md-3 mb-3" v-if="fittingDataResult.kategori === 'Non Moving'">
																							<label class="form-control-label" style="font-size:0.80vw;"><b>Komponen Terpasang </b>
																									<el-tooltip placement="top">
																											<template #content> Jumlah item sparepart yang terpasang dalam seluruh equipment <br>
																											yang sedang bekerja dalam satuan unit <br>
																											Jumlah komponen terpasang di seluruh Anper diasumsikan minimal 5 dan maksimal 10 unit
																											</template>
																											<sup><span style="font-size:0.8vw;"><i
																																	class="fa fa-exclamation-circle"></i></span></sup>
																									</el-tooltip>
																							</label>
																							<input class="form-control" style="width: 100%;" placeholder="Komponen Terpasang" v-model.number="komponenTerpasang" type="number" @input="setEmptyReturnCalcAndDiagram()" :disabled="loadingCalculate">
																					</div>	

																					<!-- Rata - Rata Demand Rate -->
																					<div class="col-md-3 mb-3" v-if="fittingDataResult.kategori !== 'Non Moving'">
																							<label class="form-control-label" style="font-size:0.85vw;"><b>Rata - Rata Demand </b>
																									<el-tooltip placement="top">
																											<template #content> Informasi Rata-Rata Demand per Tahun
																											</template>
																											<sup><span style="font-size:0.8vw;"><i
																																	class="fa fa-exclamation-circle"></i></span></sup>
																									</el-tooltip>
																							</label>
																							<input class="form-control" style="width: 100%;" placeholder="Komponen Terpasang" v-model.number="fittingDataResult.yearly_demand_rate" type="number" disabled>
																					</div>																			
                                        </div>
                                    </div>
                                    <!-- Tombol Run Calculation -->
                                    <div class="text-right mt-3">
                                        <button 
                                            class="btn btn-success" 
                                            @click="runCalculate"
                                            :disabled="loadingCalculate "
                                            id="runCalculationButton">
                                            <span v-if="loadingCalculate">
                                                <i class="fa fa-spinner fa-spin"></i> Loading...
                                            </span>
                                            <span v-else>
                                                Run Calculation
                                            </span>
                                        </button>
                                    </div>

                                    <!-- Tabel Hasil Perhitungan -->
                                    <div v-if="openTableHasilPerhitunganDanSummary" class="mt-4">
                                        <table class="table table-bordered table-striped text-center"
                                            style="margin-bottom: 40px;">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <!-- <th v-if="['Normal', 'Poisson'].includes(fittingDataResult.metode)">
                                                        Service Level
                                                        <el-tooltip placement="top">
                                                            <template #content> Kemampuan memberikan pelayanan kepada
                                                                user <br> pemeliharaan yang diukur berdasarkan rasio
                                                                <br> antara tingkat ketersediaan (availability) <br>
                                                                Material Stock dengan frekuensi <br>
                                                                permintaan.</template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th> -->
                                                    <th v-if="['Normal', 'Poisson', 'MinMax', 'Tchebycheff', 'Minimasi Regret', 'Probabilistik Kerusakan Linear', 'Probabilistik Kerusakan Nonlinear'].includes(fittingDataResult.metode)">
                                                        Safety Stock
                                                        <el-tooltip placement="top">
                                                            <template #content> Persediaan pengaman (buffer stock), yang
                                                                berguna <br> untuk menjaga kemungkinan terjadinya Stock
                                                                Out <br> yang diakibatkan karena penggunaan material
                                                                yang <br> melebihi perkiraan semula atau dikarenakan
                                                                Lead Time <br> pengiriman material yang lebih lama <br>
                                                                dari yang diperkirakan. </template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(fittingDataResult.metode)">
                                                        ROP
                                                        <el-tooltip placement="top">
                                                            <template #content> Reorder Point sebagai batas posisi
                                                                jumlah <br> stock di mana pembelian kembali material
                                                                <br> harus dilakukan untuk mengisi kembali <br> stock
                                                                gudang. </template>
                                                            <sup><span style="font-size:10px"><i
                                                                        class="fa fa-exclamation-circle"></i></span></sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <!-- <th v-if="['MinMax'].includes(fittingDataResult.metode)">
                                                        ROQ
                                                    </th> -->
                                                    <th v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(fittingDataResult.metode)">
                                                        EOQ
                                                        <el-tooltip placement="top">
																														<template #content> Rekomendasi jumlah pesanan dalam sekali pesan </template>
                                                            <sup>
																															<span style="font-size:10px">
																																<i class="fa fa-exclamation-circle"></i>
																															</span>
																														</sup>
                                                        </el-tooltip>
                                                    </th>
																										<th v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(fittingDataResult.metode)">
                                                        MAX
                                                        <el-tooltip placement="top">
                                                            <template #content> Jumlah maksimum stock yang ada di gudang </template>
                                                            <sup>
																															<span style="font-size:10px">
																																<i class="fa fa-exclamation-circle"></i>
																															</span>
																														</sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax', 'Tchebycheff'].includes(fittingDataResult.metode)">
                                                        UoM
                                                        <el-tooltip placement="top">
                                                            <template #content> Unit of Measurement </template>
                                                            <sup>
																															<span style="font-size:10px">
																																<i class="fa fa-exclamation-circle"></i>
																															</span>
																														</sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th>
                                                        Total Cost Optimal
                                                        <el-tooltip placement="top">
                                                            <!-- <template #content> Ongkos pemakain pada suatu periode</template> -->
                                                            <template #content> Total biaya yang dikeluarkan dari jumlah pesanan (EOQ) dikali harga satuan unit </template>
                                                            <sup>
																															<span style="font-size:10px">
																																<i class="fa fa-exclamation-circle"></i>
																															</span>
																														</sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax', 'Tchebycheff', 'Minimasi Regret', 'Probabilistik Kerusakan Linear', 'Probabilistik Kerusakan Nonlinear'].includes(fittingDataResult.metode)">
                                                        Metode
                                                        <el-tooltip
                                                            placement="top">
                                                            <template #content>  Jenis model yang digunakan untuk perhitungan </template>
                                                            <sup>
																															<span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span>
																														</sup>
                                                        </el-tooltip>
                                                    </th>
                                                    <th v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax', 'Tchebycheff', 'Minimasi Regret', 'Probabilistik Kerusakan Linear', 'Probabilistik Kerusakan Nonlinear'].includes(fittingDataResult.metode)">
                                                        Grafik
                                                        <el-tooltip
                                                            placement="top">
                                                            <template #content> Simulasi kondisi stok gudang dari <br> nilai-nilai perhitungan stok selama 12 bulan </template>
                                                            <sup>
																															<span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span>
																														</sup>
                                                        </el-tooltip>
                                                    </th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                <tr v-for="(result, index) in groupCalculationResults" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <!-- <td v-if="['Normal', 'Poisson'].includes(result.metode)">
                                                        {{ result.service_level }}
                                                    </td> -->
                                                    <td v-if="['Normal', 'Poisson', 'MinMax', 'Tchebycheff', 'Minimasi Regret', 'Probabilistik Kerusakan Linear', 'Probabilistik Kerusakan Nonlinear'].includes(result.metode)">
                                                        {{ result.safety_stock ?? '-' }}
                                                    </td>
                                                    <td v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(result.metode)">
                                                        {{ result.ROP }}
                                                    </td>
                                                    <td v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(result.metode)">
                                                        {{ result.EOQ }}
                                                    </td>
																										<td v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(result.metode)">
																												{{ result.max_stock ? result.max_stock : '-' }}
																										</td>
                                                    <td v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax', 'Tchebycheff'].includes(result.metode)">
                                                        {{ fittingDataResult.unit_of_measurement ? fittingDataResult.unit_of_measurement : '-' }}
                                                    </td>
                                                    <td>
                                                        {{ result.total_cost_optimal ? formatRupiah(result.total_cost_optimal) : '-' }}
                                                    </td>
                                                    <td>
                                                        {{ result.metode }}
                                                    </td>
                                                   <td style="font-size:11pt">

                                                    <button type="button" class="btn btn-sm btn-primary" @click="displayChartStockMonthly(index)" :disabled="!returnForDisplayChart.data || !displayChartGrafik.availableInvetory ">Detail</button>
                                                    
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="mt-3">
                                            <div class="d-flex flex-column align-items-end">
                                                <div class="ml-2">
                                                    <base-button size="md" @click="runDownload"
                                                        :disabled="loadingDownload" type="dark">
                                                        <span v-if="loadingDownload">Downloading...</span>
                                                        <span v-else>Download</span>
                                                        <el-tooltip placement="top">
                                                        <template #content>Download tabel hasil perhitungan</template>
                                                        <sup>
																													<span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span>
																												</sup>
                                                    </el-tooltip>
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>

                                        <h3 class="text-center" style="margin-top: 20px; margin-bottom: 20px;">
                                            <b>Summary</b>
                                        </h3>
                                        <div class="py-3" style="border: 1px solid #858585; border-radius: 5px; text-align: center;">
                                            <p style="margin: 0;">
                                                Berdasarkan hasil perhitungan di atas, disarankan nilai:<br>
                                                
                                                <!-- Safety Stock for Non-Moving Pola (Vertical) -->
                                                <span v-if="fittingDataResult.kategori === 'Non Moving'">
																									<div v-for="(result, index) in groupCalculationResults.filter(res => res.kategori === 'Non Moving')" :key="index" style="display: block; margin: 0; padding: 0;">
																										<b><b>Safety Stock dengan metode {{ result.metode }}:</b> <b>{{ result.safety_stock }}</b></b>
																									</div>
                                                </span>

                                                <!-- Safety Stock for Other Categories (Horizontal) -->
                                                <span v-else-if="['Normal', 'Poisson', 'MinMax', 'Tchebycheff', 'Minimasi Regret', 'Probabilistik Kerusakan Linear', 'Probabilistik Kerusakan Nonlinear'].includes(calculationResult.metode)">
																									<span style="display: inline-block;">
																										<b><b>Safety Stock: {{ calculationResult.safety_stock }}</b></b>
																									</span>
                                                </span>
                                                
                                                <!-- Reorder Point (ROP) -->
                                                <span v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(calculationResult.metode)">
                                                    <span style="display: inline-block;">
                                                        <span v-if="calculationResult.safety_stock !== undefined">, </span> <b><b> Reorder Point (ROP): {{ calculationResult.ROP }}</b></b>
                                                    </span>
                                                </span>
                                                
                                                <!-- Economic Order Quantity (EOQ) -->
                                                <span v-if="['Deterministik', 'Normal', 'Poisson', 'MinMax'].includes(fittingDataResult.metode)">
                                                    <span style="display: inline-block;">
                                                    <b><b>, Economic Order Quantity (EOQ): {{ calculationResult.EOQ }}</b></b>
                                                    </span>
                                                </span>
                                            </p>

                                            <!-- Additional text without extra spacing -->
                                            <p style="margin: 5px 0 0 0;">Berdasarkan Analisa SHP, material ini merupakan material <b><b>{{ calculationResult.stockNonStock }}</b></b></p>
                                            <p style="margin: 0;">Perhitungan {{ dataTa === "true" ? 'menggunakan data TA' : 'tidak menggunakan data TA' }} <span v-if="fittingDataResult.status_kontrak && fittingDataResult.status_kontrak !== 'Tidak Aktif'">&amp; memiliki status <b><b>kontrak</b></b> dengan vendor sampai <b><b>{{fittingDataResult.status_kontrak.split(", ")[1]}}</b></b></span> </p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <skeleton-loading v-if="loadingFittingData"/>
                            <empty v-if="!openTableDirekomendasikan && !loadingFittingData"></empty>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODAL SMARTCAT ATTACHMENT -->
            <modal :show.sync="displayChartGrafik.modalOpen" size="lg">
                <template slot="header">
                    <h5 class="modal-title">Detail & Grafik Hasil Perhitungan</h5>
                </template>
                <template>
										<div id="canvasWrapper">
											<canvas id="myChart"></canvas>
										</div>
                    <div class="table-responsive mt-5">
                        <table class="table table-bordered customTableKecil table-striped">
                            <tbody>
                                <tr>
                                    <th>Month</th>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                    <td>6</td>
                                    <td>7</td>
                                    <td>8</td>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>11</td>
                                    <td>12</td>
                                </tr>
                                <tr>
                                    <th>Availibility Inventory
                                        <el-tooltip content="Stok Spare Part yang ada digudang" placement="top">
                                            <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                    </th>
                                    <td v-for="(available, keyAvail) in displayChartGrafik.availableInvetory" :key="keyAvail">{{available}}</td>
                                </tr>
                                <tr>
                                    <th>Order Entry
                                        <el-tooltip content="Kuantiti Spare Part yang diterima" placement="top">
                                            <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                    </th>
                                    <td v-for="(entry, keyEntry) in displayChartGrafik.orderEntry" :class="{'text-primary fw-bold': (Number(entry) > 0)}" :key="keyEntry">{{entry}}</td>
                                </tr>
                                <tr>
                                    <th>Order Issue
                                        <el-tooltip content="Kuantiti Spare Part yang dipesan" placement="top">
                                            <sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
                                        </el-tooltip> 
                                    </th>
                                    <td v-for="(issued, keyIssues) in displayChartGrafik.orderIssued" :class="{'text-danger fw-bold': (Number(issued) > 0)}" :key="keyIssues">{{issued}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </modal>
        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Chart from 'chart.js/dist/chart.js'
import SkeletonLoading from '../../components/SkeletonLoading.vue';
import configUrl from '@/configs/config'
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

export default {
    components: { SkeletonLoading },
    data() {
        return {
					deskripsi: '',
					leadTime: 0,
					yearlyDemandRate: 0,
					unitPrice: 0,
					standarDeviasi: 0,
					komponenTerpasang: 5,
					stockOutEffect: 3700000000,
					// stockOutEffect: 3730000000,
					holdingCost: 15,
					// holdingCost: 5,
					orderCost: 0,
					serviceLevel: 90,
					afterFirstRunCalc: false,

					dataTa: null,
					dataFitting: {
							plant: null,
							materialNumber: null
					},

					searchQuery: '',
					selectedMaterialNumber: '',

					plants: [
							{ code: 'B601', name: 'PKG' },
							{ code: 'C601', name: 'PKC' },
							{ code: 'D601', name: 'PKT' },
							{ code: 'E601', name: 'PIM' },
							{ code: 'F601', name: 'PSP' },
							{ code: 'I601', name: 'PILOG' },
							{ code: 'J601', name: 'PIU' },
					],

					loadingCalculate: false,
					loadingFittingData: false,
					loadingDownload: false,
					loadingSearch: false,

					matrixDialogVisible: false,

					fittingDataResult: {},
					calculationResult: null, //untuk ditampilkan pada summary
					groupCalculationResults: {}, //untuk ditampilkan pada table hasil run calculation
					
					// isServiceLevelDisabled: true, // Mulai dengan serviceLevel disabled
					allMonthsData: [],
					demandRequest: [],
					realDataDemandData: {},
					// total_cost_optimal: null, // Inisialisasi dengan null atau nilai default yang diperlukan
					apiUrl: configUrl.apiUrl,

					openTableHasilPerhitunganDanSummary: false,
					openDemandRate: false,
					openTableDirekomendasikan: false,

					stockNonStock: null, // Misalnya jika kamu menggunakannya di template
					stockStrategyData: [],
					// selectSearch: {
					// 		loadTimeout: null
					// },
					loadTimeoutFetchMatNum: null,
					returnForDisplayChart: {
							data: [],
					},
					displayChartGrafik: {
							modalOpen: false,
							canChart: null,
							availableInvetory: [],
							orderEntry: [],
							orderIssued: []
					}
        }

    },
    computed: {
			...mapState({
				authUserPermission: state => state.auth.userPermission,
				authUser: state => state.auth.user
			}),
			yearOptions() {
				const result = [];
				const currentYear = new Date().getFullYear();
				for (let i = 0; i <= 6; i++) {
						result.push(currentYear - i);
				}
				return result;
			},
			isFittingDataDisabled() {
				return !this.dataTa || !this.dataFitting.plant || !this.dataFitting.materialNumber || this.loadingFittingData;
			},
			formattedUnitPrice: {
				get() {
						return new Intl.NumberFormat("id-ID", {
						style: "currency",
						currency: "IDR",
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
						}).format(parseInt(this.unitPrice));
				},
				set(newValue) {
						const rawValue = parseInt(newValue.replace(/[^0-9]/g, "")); 
						this.unitPrice = isNaN(rawValue) ? 0 : rawValue;

						this.setEmptyReturnCalcAndDiagram()
				},
			},
			formattedOrderCost: {
				get() {
						return this.orderCost.toLocaleString('id-ID', {
								style: 'currency',
								currency: 'IDR',
								minimumFractionDigits: 0
						}).replace('Rp', 'Rp.'); // Format: Rp. 5.000.000
				},
				set(value) {
						// Hapus simbol 'Rp.' dan format sebagai angka
						const numberValue = parseInt(value.replace(/Rp\.|[^0-9]/g, ''), 10);
						this.orderCost = numberValue || 0;

						this.setEmptyReturnCalcAndDiagram()
				}
			},
			formattedStockOutEffect: {
				get() {
						return this.stockOutEffect.toLocaleString('id-ID', {
								style: 'currency',
								currency: 'IDR',
								minimumFractionDigits: 0
						}).replace('Rp', 'Rp.');
				},
				set(newValue) {
						// Menghilangkan format Rp. dan koma
						const cleanedValue = newValue.replace(/[^0-9]/g, '');
						this.stockOutEffect = parseInt(cleanedValue, 10) || 0;

						this.setEmptyReturnCalcAndDiagram()
				}
			},
			formattedLeadTime: {
				get() {
						return this.leadTime.toFixed(2);
				},
				set(value) {
						this.leadTime = parseFloat(value);

						this.setEmptyReturnCalcAndDiagram()
				}
			},
    },
    watch: {
        unitPrice(newValue) {
            if (newValue > 100000000) {
                this.orderCost = 5000000; // 5 juta
            } else {
                this.orderCost = 1000000; // 1 juta
                // this.orderCost = 500000;
            }
        },
        // selectedMaterialNumber(newVal) {
        //     if (newVal) {
        //         this.fetchHistoryMonthly();
        //     }
        // },
        demandRequest: {
            handler() {
                this.saveDemandRequest();
            },
            deep: true
        },
    },
    mounted() {
			this.komponenTerpasang = 5;
			// // Set initial orderCost based on the initial unitPrice
			// if (this.unitPrice > 100000000) {
			// 		this.orderCost = 5000000; // 5 juta
			// } else {
			// 		this.orderCost = 1000000; // 1 juta
			// 		// this.orderCost = 500000; // 1 juta
			// }

			this.fetchHistoryMonthly();
			// this.getStockStrategyFromCsv();
			this.initializeSelectedPlant();
    },
    methods: {
			initializeSelectedPlant() {
					const mapping = {
						B000: 'B601',
						C000: 'C601',
						D000: 'D601',
						E000: 'E601',
						F000: 'F601',
						G000: 'G601',
						H000: 'H601',
						I000: 'I601',
						J000: 'J601',
					};

					// Cek apakah company_code ada dalam mapping
					if (this.authUser.company_code in mapping) {
						this.dataFitting.plant = mapping[this.authUser.company_code];
					}
			},
			async handleFittingData() {
					this.openDemandRate = false
					this.openTableDirekomendasikan = false
					this.loadingFittingData = true
					this.openTableHasilPerhitunganDanSummary = false
					this.afterFirstRunCalc = false
					// this.isServiceLevelDisabled = true

					const postData = {
							"use_data_ta": this.dataTa === "true",
							"plant": this.dataFitting.plant,
							"material_codes": [String(this.selectedMaterialNumber)]
					};
					var config = { method: 'POST', url: 'https://stock-calc-be-v2.pupuk.in/fit-data', headers: { "Content-Type": "application/json" }, data: postData};

					try {
						const response = await axios(config);

						if (!response.data[0].deskripsi.includes('tidak ditemukan')) {
							response.data.forEach((item) => {
								if (item.yearly_demand_rate) {
									item.yearly_demand_rate = parseFloat(item.yearly_demand_rate.toFixed(2))									
								}
							})
							this.fittingDataResult = response.data[0];

							// Update values from API response
							this.unitPrice = this.fittingDataResult.unit_price ?? 0; 
							this.leadTime = this.fittingDataResult.lead_time ?? 0;
							this.standarDeviasi = this.fittingDataResult.standar_deviasi ?? 0;
							// Atur Default Sevice level 90 untuk kategori Tak Tentu
							this.serviceLevel = ['Normal', 'Poisson', 'Tak Tentu'].includes(this.fittingDataResult.kategori) ? this.valueServiceLevel(this.dataFitting.plant) : 0;

							if (this.fittingDataResult.kategori === 'Non Moving') {
								let getTotalQtyInstalled = await this.getEqpQtyInstalled()
								this.komponenTerpasang = this.fittingDataResult.kategori === 'Non Moving' && getTotalQtyInstalled !== 0 ? getTotalQtyInstalled : 5;
							}
							// this.komponenTerpasang = 5;

							if (['Normal', 'Poisson', 'Tak Tentu', 'Deterministik', 'Non Moving'].includes(this.fittingDataResult.kategori)) {
								await	this.fetchHistoryMonthly();
							}

							this.openTableDirekomendasikan = true
							// console.log('handleFittingData', response.data, getTotalQtyInstalled)

						} else {
							this.$notify({
									message: "Your Data material is not found.",
									type: 'danger',
									verticalAlign: "bottom",
									horizontalAlign: "left"
							});
						}
					} catch (error) {
						this.$notify({
								message: 'Error fetching fitting data: ' + error,
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});

						this.openTableDirekomendasikan = false
					} finally {
						this.loadingFittingData = false;
						if (['Normal', 'Tak Tentu', 'Poisson', 'Deterministik'].includes(this.fittingDataResult.kategori)) {
								this.openDemandRate = true
						}
					}
			},
			async runCalculate() {
				if (this.fittingDataResult.kategori !== 'Deterministik') {
					Swal.fire({
						title: 'Confirmation',
						html: 'Apakah Anda yakin dengan nilai <b>Stock Out Effect</b>?',
						icon: 'warning', // Tanda seru peringatan
						showCancelButton: true,
						confirmButtonColor: '#5e72e4',
						cancelButtonColor: '#f5365c',
						confirmButtonText: 'Yes',
						heightAuto:false,
					}).then(result => {
						if (result.isConfirmed) {
							this.runCalclulationFunction()
						}
					});
				} else {
					this.runCalclulationFunction()
				}
			},
			async runCalclulationFunction() {
				if (!this.fittingDataResult || !this.fittingDataResult.kategori) {
						this.$notify({
								message: 'fittingDataResult is not filled in correctly',
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});
						return false;
				}
				this.openTableHasilPerhitunganDanSummary = false;
				this.loadingCalculate = true;
				// this.isServiceLevelDisabled = true;

				if (['Tak Tentu', 'Deterministik', 'Normal', 'Poisson'].includes(this.fittingDataResult.kategori)) {
						if ((!this.demandRequest || this.demandRequest.length === 0)) {
								this.$notify({
										message: 'Demand Request is not filled in or is empty',
										type: 'danger',
										verticalAlign: "bottom",
										horizontalAlign: "left"
								});
								
								this.loadingCalculate = false;
								return false;
						}

						if (['Normal', 'Deterministik', 'Poisson', 'Tak Tentu'].includes(this.fittingDataResult.kategori) && this.unitPrice === 0) {
								this.$notify({
										message: 'Unit Price cannot be 0',
										type: 'danger',
										verticalAlign: "bottom",
										horizontalAlign: "left"
								});
								this.loadingCalculate = false;
								return false
						}

						if (['Normal', 'Deterministik', 'Poisson', 'Tak Tentu'].includes(this.fittingDataResult.kategori) && this.holdingCost === 0) {
										this.$notify({
												message: 'Holding Cost cannot be 0',
												type: 'danger',
												verticalAlign: "bottom",
												horizontalAlign: "left"
										});
										this.loadingCalculate = false;
										return false   
						}

						if (['Normal', 'Poisson', 'Deterministik'].includes(this.fittingDataResult.kategori) && this.orderCost === 0) {
										this.$notify({
												message: 'Order Cost cannot be 0',
												type: 'danger',
												verticalAlign: "bottom",
												horizontalAlign: "left"
										});
										this.loadingCalculate = false;
										return false   
						}

						if (this.fittingDataResult.kategori === 'Normal' && this.stockOutEffect === 0) {
										this.$notify({
												message: 'Stock Out Effect cannot be 0',
												type: 'danger',
												verticalAlign: "bottom",
												horizontalAlign: "left"
										});
										this.loadingCalculate = false;
										return false   
						}
				}

				if (this.fittingDataResult.kategori === 'Non Moving' && this.komponenTerpasang === 0) {
						this.$notify({
								message: 'Installed Component cannot be 0',
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});
						this.loadingCalculate = false;
						return false
				}

				let yearlyDemandRate
				// cek apakah ada perbedaan antara value pada setiap array this.allMonthsData dan this.demandRequest
				if (this.checkDifferences()) {
					yearlyDemandRate = this.fittingDataResult.kategori !== 'Non Moving' ? this.calculateYearlyDemandRate() : undefined
				} else {
					yearlyDemandRate = this.fittingDataResult.kategori !== 'Non Moving' ? this.fittingDataResult.yearly_demand_rate : undefined
				}
				// console.log('cek all month data', JSON.stringify(this.allMonthsData), JSON.stringify(this.demandRequest), this.fittingDataResult.yearly_demand_rate, this.checkDifferences())

				// format run calculation
				const inputData = [
						{
							"plant": String(this.dataFitting.plant),
							"material": String(this.selectedMaterialNumber),
							"kategori": this.fittingDataResult.kategori,
							"input_parameter": {
								lead_time: this.leadTime ?? 0,
								unit_price: this.unitPrice,
								...( this.orderCost ? { order_cost: this.orderCost } : {order_cost: 0}),
								...(['Normal', 'Deterministik', 'Poisson'].includes(this.fittingDataResult.kategori) ? {holding_cost: this.holdingCost} : {}),
								...(['Non Moving'].includes(this.fittingDataResult.kategori) ? {jumlah_komponen_terpasang: this.komponenTerpasang} : {}),
								...( this.stockOutEffect ? {stock_out_effect: this.stockOutEffect} : {stock_out_effect: 3700000000} ),
								...(['Normal', 'Deterministik', 'Poisson', 'Tak Tentu'].includes(this.fittingDataResult.kategori) ? { material_category: this.fittingDataResult.material_category, current_stock: this.fittingDataResult.current_stock } : {}),
								...( this.fittingDataResult.kategori === 'Tak Tentu' ? { lead_time_max: this.fittingDataResult.lead_time_max ?? 0 , lead_time_min: this.fittingDataResult.lead_time_min ?? 0, lead_time_std: this.fittingDataResult.lead_time_std ?? 0, mean_demand: this.fittingDataResult.mean_demand ?? 0, std_demand: this.fittingDataResult.std_demand ?? 0, min_demand: this.fittingDataResult.min_demand ?? 0, holding_cost: this.holdingCost ?? 5 } : {}),
								...(typeof yearlyDemandRate === 'number' && !isNaN(yearlyDemandRate) && yearlyDemandRate !== undefined ? { yearly_demand_rate: yearlyDemandRate, standar_deviasi: this.standarDeviasi} : {}),
								...(['Normal', 'Poisson', 'Tak Tentu'].includes(this.fittingDataResult.kategori) ? { service_level: parseFloat(this.serviceLevel > 99.99 ? 99.99 : this.serviceLevel) } : {}),
							}
						}
				];

				console.log('before runCalculation', inputData)
				try {
					const response = await axios.post('https://stock-calc-be-v2.pupuk.in/calculate', inputData, {
						headers: { "Content-Type": "application/json" }
					});

					this.groupCalculationResults = response.data.map(rslt => {
						if (rslt.safety_stock) {
							// rslt.safety_stock = parseFloat(rslt.safety_stock.toFixed(2))
							rslt.safety_stock = Math.ceil(rslt.safety_stock)
						}
						return rslt
					})

					if (this.groupCalculationResults.length > 0) {
						// Set calculationResult to the first item or process as needed
						this.calculationResult = this.groupCalculationResults[0];

						// sisipin metode ke dalam fittingDataResult
						this.fittingDataResult.metode = this.calculationResult.metode

						// stock non stock
						// const stockResponse = this.stockStrategyData.find(item => item.material_code === parseInt(inputData[0].material, 10));
						// stockResponse ? this.calculationResult.stockNonStock = stockResponse.display_stock_strategy : this.calculationResult.stockNonStock = 'Belum Dianalisa SHP'
						if (this.fittingDataResult.stock_strategy) {
							this.calculationResult.stockNonStock = this.fittingDataResult.stock_strategy;
						} else {
							this.calculationResult.stockNonStock = 'Belum Dianalisa SHP';
						} 

						if (this.calculationResult.service_level) {
							this.serviceLevel = this.calculationResult.service_level;
						} else if (this.calculationResult.metode === 'MinMax'){
							this.serviceLevel = this.valueServiceLevel(this.dataFitting.plant);
						} else {
							this.serviceLevel = '';
						}

						// hasilkan value untuk display chart
						this.resultForDisplayChart()

						console.log('after run calculation', inputData, this.calculationResult, this.returnForDisplayChart, this.fittingDataResult, this.calculateYearlyDemandRate())

						// this.isServiceLevelDisabled = false;
						this.openTableHasilPerhitunganDanSummary = true;
						this.afterFirstRunCalc = true
					}
				} catch (error) {
						console.log(error.message, error, + ', runCalculate ' + error.response)
						this.$notify({
								message: error.message,
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});

						this.openTableDirekomendasikan = false 
						this.loadingFittingData = false
						this.openDemandRate = false
						this.openTableHasilPerhitunganDanSummary = false;
				
				} finally {
						this.loadingCalculate = false;
				}
			},
			fetchSuggestionsMasterMaterialNumber(queryString, cb) {
					if (!this.dataFitting.plant || queryString.length < 1) return cb([{label: 'No Data'}]);
					const params = {
							plant: this.dataFitting.plant,
							material: queryString,
					};

					clearTimeout(this.loadTimeoutFetchMatNum);
					this.loadTimeoutFetchMatNum = setTimeout(async () => {
							this.loadingSearch = true;
							try {
									const response = await axios.get('https://stock-calc-be-v2.pupuk.in/master-material', { params });
									const results = response.data;

									if (results.length > 0) {
											cb(results.slice(0, 500).map(item => ({
													value: item.MATERIAL,
													label: `${item.MATERIAL} - ${item.DESCRIPTION}`,
													MATERIAL: item.MATERIAL,
													DESCRIPTION: item.DESCRIPTION,
											})));
									} else {
											cb([{label: 'No Data'}])
									}
							} catch (error) {
									cb([]);
									console.log(error + ', fetchSuggestionsMasterMaterialNumber')
									this.$notify({
											message: error.message,
											type: "danger",
											verticalAlign: "bottom",
											horizontalAlign: "left"
									});
							} finally {
									this.loadingSearch = false;
							}
					}, 500);
			},
			handleSelectMaterialNumber(item) {
					this.searchQuery = item.label; 
					this.selectedMaterialNumber = item.MATERIAL;
					this.dataFitting.materialNumber = item.MATERIAL;
					this.deskripsi = item.DESCRIPTION;
			},
			changePlant() {
					this.searchQuery = ''; 
					this.selectedMaterialNumber = '';
					this.dataFitting.materialNumber = '';
					this.deskripsi = '';
			},
			valueServiceLevel(value) {				
				const plantName = {
						'B601': 60,
						'C601': 80,
						'D601': 85,
						'E601': 90,
						'F601': 90,
						'I601': 90,
						'J601': 90
					}[value] || '';

					return plantName;
			},
			namePlant(value) {				
				const plantName = {
						'B601': 'PKG',
						'C601': 'PKC',
						'D601': 'PKT',
						'E601': 'PIM',
						'F601': 'PSP',
						'I601': 'PILOG',
						'J601': 'PIU'
					}[value] || '';

				return plantName;
			},
			checkDifferences() {
				// Pastikan panjang array sama
				if (this.demandRequest.length === this.allMonthsData.length) {
					// Bandingkan elemen satu per satu
					const hasDifference = this.demandRequest.some((value, index) => {
							return value !== this.allMonthsData[index].value;
					});

					return hasDifference; // Return true jika ada perbedaan, false jika semua sama
				} else {
					return true
				}
			},
			getCurrentMonthYear() {
					const now = new Date();
					const month = now.getMonth(); // 0-11
					const year = now.getFullYear();
					return { month, year };
			},
			async fetchHistoryMonthly() {
					if (!this.selectedMaterialNumber || this.fittingDataResult.kategori === 'Non Moving') return;

					const postData = {
							"use_data_ta": this.dataTa === "true",
							"plant": this.dataFitting.plant,
							"material": String(this.selectedMaterialNumber)
					};
					// setTimeout( async() => {
					try {
							const response = await axios.post('https://stock-calc-be-v2.pupuk.in/history-monthly', postData, {
									headers: { "Content-Type": "application/json" }
							})
							// mengambil bulan dan tahun terkini
							const { month, year } = this.getCurrentMonthYear()
							const firstDemandDate = new Date(response.data.first_demand_date)
							this.allMonthsData = []

							for (const responseYear in response.data.demand_data) {
									if (parseInt(responseYear) > year) continue;
									

									const monthlyData = response.data.demand_data[responseYear] || [];
									monthlyData.forEach((value, index) => {
											const certainDate = new Date(responseYear, index)


											// Skip if the year-month combination is before the firstDemandDate's year-month
											if (certainDate.getFullYear() < firstDemandDate.getFullYear() || (certainDate.getFullYear() === firstDemandDate.getFullYear() && certainDate.getMonth() < firstDemandDate.getMonth())
											) {
													return;
											}
											
											// skip bulan sebelum month meskipun di tahun saat ini
											if (parseInt(responseYear) === year && index > month) return;

											const monthYear = `${certainDate.toLocaleString('id', { month: 'short' })} ${responseYear}`;
											this.allMonthsData.push({ monthYear, value });
									});
							}

							this.initializeDemandRequest();
					} catch (error) {
							console.log(error + ', fetchHistoryMonthly')
							this.$notify({
									message: error.message + ', fetchHistoryMonthly',
									type: "danger",
									verticalAlign: "bottom",
									horizontalAlign: "left"
							});

							this.openDemandRate = false
							this.openTableDirekomendasikan = false
							this.loadingFittingData = false
							this.openTableHasilPerhitunganDanSummary = false
					} 
					// }, 50)
			},
			initializeDemandRequest() {
					if (this.fittingDataResult.kategori === 'Non Moving') {
							this.demandRequest = [];
							return;
					}

					this.demandRequest = this.allMonthsData.map(item => item.value || 0);
			},
			calculateYearlyDemandRate() {
				const currentYear = new Date().getFullYear(); // Tahun saat ini
				const filteredData = {};

				if (this.realDataDemandData.length === 0) {
						this.$notify({
								message: 'Gagal load demand rate',
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});
						return 0
				}

				// Filter data untuk mengabaikan tahun yang belum selesai
				for (const year in this.realDataDemandData) {
						if (parseInt(year) < currentYear) {
								filteredData[year] = this.realDataDemandData[year]
						}
				}

				const sumYears = Object.keys(filteredData).length;
				const monthlySums = Array(12).fill(0);
				for (const year in filteredData) {
						for (let i = 0; i < filteredData[year].length; i++) {
								monthlySums[i] += filteredData[year][i]
						}
				}
				// Menghitung rata-rata untuk setiap bulan
				const monthlyAverages = monthlySums.map(sum => sum / sumYears);
				// Menghitung rata-rata tahunan
				const yearlyDemandAvgs = monthlyAverages.reduce((acc, avg) => acc + avg, 0)
				return yearlyDemandAvgs
			},
			saveDemandRequest() {
				if (this.fittingDataResult.kategori === 'Non Moving') return;

				const demandRequestAllYears = {};
				const monthMapping = {
						'Jan': 'Jan', 'Feb': 'Feb', 'Mar': 'Mar', 'Apr': 'Apr',
						'Mei': 'May', 'Jun': 'Jun', 'Jul': 'Jul', 'Agu': 'Aug',
						'Sep': 'Sep', 'Okt': 'Oct', 'Nov': 'Nov', 'Des': 'Dec'
				};

				// Simpan data request demand berdasarkan bulan
				this.allMonthsData.forEach((item, index) => {

						if (item?.monthYear && this.demandRequest[index] !== undefined) {
								const [monthName, year] = item.monthYear.split(' ');
								const englishMonthName = monthMapping[monthName];  // Konversi ke nama bulan Inggris
								const monthIndex = new Date(`${englishMonthName} 1, ${year}`).getMonth();

								if (!demandRequestAllYears[year]) {
										// Memulai array pada tahun tertentu dengan menambahkan 0 untuk bulan sebelum material ini dibuat
										demandRequestAllYears[year] = Array(monthIndex).fill(0);
								}

								// Add the demand value to the correct month position
								demandRequestAllYears[year][monthIndex] = this.demandRequest[index];
						} else {
								// console.warn('Skipping item due to missing data:', item);
						}
				});
				this.realDataDemandData = demandRequestAllYears
			},
			formatRupiah(value) {
				if (value === null || value === undefined) return '';
				return `Rp ${Number(value).toLocaleString('id-ID')}`;
			},
			handlePlantChange(value) {
				this.dataFitting.plant = value;
			},
			setEmptyReturnCalcAndDiagram() {
				this.openTableHasilPerhitunganDanSummary = false
				this.groupCalculationResults = []
				this.calculationResult = null
				this.returnForDisplayChart.data = [] 
				this.displayChartGrafik = {
					modalOpen: false,
					canChart: null,
					availableInvetory: [],
					orderEntry: [],
					orderIssued: []
				}
			},
			resetParameter() {

					this.dataTa = null,
					// this.dataFitting.plant = null 
					this.dataFitting.materialNumber = null 
					this.fittingDataResult = {};
					this.allMonthsData = [];
					this.demandRequest = []; 

					this.unitPrice = 0; 
					this.orderCost = 0;
					this.stockOutEffect = 3700000000;
					// this.stockOutEffect = 3730000000;
					this.leadTime = 0;
					this.yearlyDemandRate = 0;
					this.komponenTerpasang = 5;
					this.standarDeviasi = 0;
					this.holdingCost = 15;
					// this.holdingCost = 5;
					this.serviceLevel = 90; // Pastikan ini sesuai dengan tipe data yang diharapkan
					this.groupCalculationResults = {}
					this.calculationResult = null;

					this.searchQuery = ''; 
					this.selectedMaterialNumber = '';
					this.deskripsi = '';

					this.displayChartGrafik.modalOpen = false
					this.displayChartGrafik.canChart = null
					this.displayChartGrafik.availableInvetory = []
					this.displayChartGrafik.orderEntry = []
					this.displayChartGrafik.orderIssued = []

					// this.demandHistory = Array(12).fill(0);
					this.loadingCalculate = false;
					this.loadingFittingData = false;
					this.loadingDownload = false;
					this.loadingSearch = false;


					this.matrixDialogVisible = false;
					this.realDataDemandData = {};
					this.openTableHasilPerhitunganDanSummary = false;
					this.openDemandRate = false;
					this.openTableDirekomendasikan = false;

					// this.isServiceLevelDisabled = true;
					// this.total_cost_optimal = null;
					// this.stockNonStock = null; // Inisialisasi jika digunakan
			},
			resultForDisplayChart() {
				this.returnForDisplayChart.data = []
				const { month, year } = this.getCurrentMonthYear();
				const realDemandHistInPreviousYear = this.realDataDemandData[year - 1];
				const requests = this.groupCalculationResults.length === 1 
						? [this.calculationResult] 
						: this.groupCalculationResults;

				requests.forEach(async (rtnCalc) => {
					const requestData = {
							lead_time: this.leadTime ?? 0,
							initial_inventory: this.fittingDataResult.current_stock ?? 0,
							ROP: rtnCalc.ROP ?? 0,
							EOQ: rtnCalc.EOQ ?? 0,
							safety_stock: rtnCalc.safety_stock ?? 0,
							request_demand: realDemandHistInPreviousYear
					};

					// setTimeout(async () => {
					try {
							const response = await axios.post('https://stock-calc-be-v2.pupuk.in/stock-monthly', requestData, {
									headers: { "Content-Type": "application/json" }
							});

							// menghapus spasi separator pada key menjadi -
							const transformedData = {};
							for (const key in response.data) {
									const newKey = key.replace(/\s+/g, '_').toLowerCase();
									transformedData[newKey] = response.data[key];
							}

							this.returnForDisplayChart.data.push(transformedData);
							// console.log('resultForDisplayChart', this.returnForDisplayChart.data)

					} catch (error) {
						console.log(error + ', resultForDisplayChart')								
						this.$notify({
								message: error.message + ', resultForDisplayChart',
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});
						this.returnForDisplayChart.data = [];
					}
					// }, 100);
				});
			},
			displayChartStockMonthly(key) {

				this.displayChartGrafik.modalOpen = true;

				const canvasWrapper = document.getElementById('canvasWrapper');
				canvasWrapper.innerHTML = '<canvas id="myChart"></canvas>'

				// const ctx = document.getElementById('myChart');
				const ctx = document.getElementById('myChart').getContext('2d');
				if (!ctx) {
						this.$notify({
								message: "Canvas element not found with ID 'myChart'.",
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});
						return;
				}

				const DATA_COUNT = 13;
				const labels = [];
				for (let i = 1; i < DATA_COUNT; ++i) {
						labels.push(i.toString());
				}
				const datapoints1   = this.returnForDisplayChart.data[key].available_inventory  
				// const datapoints2   = this.returnForDisplayChart.data[key].order_entry
				// const datapoints3   = this.returnForDisplayChart.data[key].order_issue
				const datapoints2   = Array(12).fill(Number(this.returnForDisplayChart.data[key].safety_stock))
				const datapoints3   = Array(12).fill(Number(this.returnForDisplayChart.data[key].reorder_point))

				this.displayChartGrafik.availableInvetory = this.returnForDisplayChart.data[key].available_inventory;
				this.displayChartGrafik.orderEntry = this.returnForDisplayChart.data[key].order_entry
				this.displayChartGrafik.orderIssued = this.returnForDisplayChart.data[key].order_issue
				
				const data = {
						labels: labels,
						datasets: [
								{
										label: 'Availibility Inventory',
										data: datapoints1,
										borderColor: 'rgb(255, 0, 0)',
										fill: false,
										cubicInterpolationMode: 'monotone',
										tension: 0.4
								}, {
										label: 'Safety Stock',
										data: datapoints2,
										borderColor: 'rgb(0, 255, 0)',
										fill: false,
										tension: 0.4,
										pointRadius:0,
										segment:{
												borderDash:[6,6]
										}
								}, {
										label: 'Reorder Point',
										data: datapoints3,
										borderColor: 'rgb(0, 0, 255)',
										fill: false,
										pointRadius:0,
										segment:{
												borderDash:[6,6]
										}
								}
						]
				};

				this.displayChartGrafik.canChart = new Chart(ctx, {
					type: 'line',
					data: data,
					options: {
						responsive: true,
						plugins: {
								title: {
										display: true,
										text: 'Order Point Technique'
								},
								tooltip:{
										callbacks:{
												title: function(context){
														return `Month (${context[0].label})`
												}
										}
								}
						},
						interaction: {
								intersect: false,
						},
						scales: {
								x: {
										display: true,
										title: {
												display: true,
												text: 'Month',
										}
								},
								y: {
										display: true,
										title: {
												display: true,
												text: 'Unit'
										},
										suggestedMin: 0,
								}
						}
					},
				});
			},
			async runDownload() {
				this.loadingDownload = true;
				try {
						// Cek apakah calculationResult adalah array atau objek tunggal
						const results = Array.isArray(this.groupCalculationResults) ? this.groupCalculationResults : [this.groupCalculationResults];

						// Menentukan data yang akan dimasukkan ke dalam Excel berdasarkan kategori
						let data = [];

						results.forEach(result => {
								if (['Normal', 'Poisson'].includes(this.fittingDataResult.kategori)) {
										data.push({
												'Material': result.material,
												'Kategori': result.kategori,
												'Plant' : this.namePlant(result.plant),
												'Metode': result.metode,
												'Service Level': result.service_level,
												'Safety Stock': result.safety_stock,
												'ROP': result.ROP,
												'EOQ': result.EOQ,
												'MAX': result.max_stock,
												'UoM': this.fittingDataResult.unit_of_measurement,
												'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
										});
								} else if (this.fittingDataResult.kategori === 'Tak Tentu') {
										data.push({
												'Material': result.material,
												'Kategori': result.kategori,
												'Plant' : this.namePlant(result.plant),
												'Metode': result.metode,
												'Safety Stock': result.safety_stock,
												'ROP': result.ROP,
												'EOQ': result.EOQ,
												'MAX': result.max_stock,
												'UoM': this.fittingDataResult.unit_of_measurement,
												// 'Min Stock': result.min_stock,
												// 'Max Stock': result.max_stock,
												'Total Biaya': result.total_biaya ? this.formatRupiah(result.total_biaya) : this.formatRupiah(result.total_cost_optimal) ?? '-',
										});
								} else if (this.fittingDataResult.kategori === 'Non Moving') {
										data.push({
												'Material': result.material,
												'Kategori': result.kategori,
												'Plant' : this.namePlant(result.plant),
												'Metode': result.metode,
												'Safety Stock': result.safety_stock,
												'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
										});
								} else if (this.fittingDataResult.kategori === 'Deterministik') {
										data.push({
												'Material': result.material,
												'Kategori': result.kategori,
												'Plant' : this.namePlant(result.plant),
												'Metode': result.metode,
												'ROP': result.ROP,
												'EOQ': result.EOQ,
												'MAX': result.max_stock,
												'UoM': this.fittingDataResult.unit_of_measurement,
												'Total Cost Optimal': this.formatRupiah(result.total_cost_optimal),
										});
								}
						});

						// Membuat worksheet dan workbook
						const ws = XLSX.utils.json_to_sheet(data);
						const wb = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(wb, ws, 'Results');

						// Membuat file Excel
						const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

						// Mendapatkan tanggal saat ini
						const now = new Date();
						const year = now.getFullYear();
						const month = String(now.getMonth() + 1).padStart(2, '0');
						const day = String(now.getDate()).padStart(2, '0');

						// Menambahkan tanggal ke nama file
						const fileName = `calculation_result_${this.fittingDataResult.kategori}_${year}-${month}-${day}.xlsx`;

						// Membuat file blob untuk diunduh
						const fileUrl = window.URL.createObjectURL(new Blob([excelBuffer]));

						// Membuat elemen <a> sementara untuk download file
						const link = document.createElement('a');
						link.href = fileUrl;
						link.setAttribute('download', fileName);

						// Menambahkan link ke body dan memicu klik untuk mendownload file
						document.body.appendChild(link);
						link.click();

						// Membersihkan elemen setelah download selesai
						link.remove();
						window.URL.revokeObjectURL(fileUrl);
				} catch (error) {
						// console.error('Gagal membuat dan mendownload file Excel:', error);
						console.log(error + ', download')														
						this.$notify({
								message: 'Gagal membuat dan mendownload file Excel: ' + error,
								type: 'danger',
								verticalAlign: "bottom",
								horizontalAlign: "left"
						});
				} finally {
						this.loadingDownload = false;
				}
			},
			openMatrixDialog() {
					this.matrixDialogVisible = true;
			},
			handleDialogClose() {
			},
			async getEqpQtyInstalled() {
				const getData = {
					"material_number": String(this.selectedMaterialNumber),
					"plant": this.dataFitting.plant,
				}

				try {
					const response = await axios.get(this.apiUrl + 'get-equipment-quantity-installed', {	params: getData, headers: { "Content-Type": "application/json" } })
					return response.data.data
				} catch (error) {
					return error.response.data.data.length
				} 
			},
			pleaseCompleteDataNotify(title) {
					this.$notify({
							message: this.tt("please_complete_data", { title: title }),
							type: 'danger',
							verticalAlign: "bottom",
							horizontalAlign: "left"
					});
			},
			// addServiceLevel() {
			// 		if (this.serviceLevel.length == 0) {
			// 				this.serviceLevel.push('')
			// 		} else if (this.serviceLevel.length > 0) {
			// 				if (this.serviceLevel[this.serviceLevel.length - 1] != '') {
			// 						this.serviceLevel.push('')
			// 				} else {
			// 						this.pleaseCompleteDataNotify('Service Level')
			// 				}
			// 		}
			// },
        // async dependCompany() {
        //     var params = JSON.stringify({
        //         "Perusahaan": this.anakPerusahaan
        //     })
        //     var config = {
        //         method: 'post',
        //         url: 'https://api-fm.pupuk-indonesia.com/material-list',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         data: params
        //     };
        //     await axios(config).then(response => this.materialNumbers = response.data["Material"]).catch(error => {
        //         this.$notify({
        //             message: 'Retrieved Data Company Failed',
        //             type: 'danger',
        //             verticalAlign: "bottom",
        //             horizontalAlign: "left"
        //         });
        //     })
        //         .catch(() => this.materialNumbers = []);
        // },

    }
};
</script>

<style>
.el-tooltip__popper {
	font-size: 14px !important;
	max-width: 350px !important;
	word-break: break-word;
}
/* 
.custom-tooltip {
    font-size: 14px !important;
} */

.customTableKecil tbody tr th,
td {
    padding: 6px !important;
    text-align: center;
}

.customTableInputKecil tbody tr td input {
    height: 30px !important;
    width: 100% !important;
    text-align: center;
}

/* sahrul */
.btn-yellow {
    background-color: rgb(252, 165, 3) !important;
    /* Warna latar belakang kuning */
    color: rgb(8, 7, 7) !important;
    /* Warna teks hitam */
    border-color: rgb(221, 201, 68) !important;
    /* Warna tepi kuning */
}

.btn-yellow:hover {
    background-color: darkorange !important;
    /* Warna latar belakang kuning tua saat dihover */
    border-color: darkorange !important;
    /* Warna tepi kuning tua saat dihover */
    color: rgb(239, 231, 231) !important;
    /* Warna teks hitam */
}

/* pop up matrix */
.table-container {
  overflow-x: auto;
  /* margin-bottom: 1rem; */
}

.matrix-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 10px; /* Ukuran font lebih kecil */
  min-width: 600px; /* Lebar minimum lebih kecil */
}

.matrix-table th,
.matrix-table td {
  border: 1px solid #0d0909; /* Border lebih tipis */
  padding: 5px; /* Padding lebih kecil */
}

.matrix-table th {
  background-color: #2a4f8a;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.variable-header {
  background-color: #009688;
}

.method-header {
  background-color: #2196f3;
}

.sub-header {
  background-color: #64b5f6;
}

.bcr-header {
  background-color: #ffc107;
}

.matrix-table tbody td {
  background-color: #f9f9f9;
}

.matrix-table tbody tr:nth-child(even) td {
  background-color: #fff;
}

.table-header-center {
  text-align: center;
  font-weight: bold;
}

/* button fitting, reset matrix*/
.base-button {
    display: inline-flex; /* Tombol akan menyesuaikan dengan teks */
    align-items: center; /* Vertikal alignment teks */
    font-size: 0.647rem; /* Ukuran font default (misalnya, 14px) */
    padding: 12px 6px; /* Padding yang konsisten */
    border: none; /* Menghapus border default jika diperlukan */
    text-align: center; /* Menyelaraskan teks di tengah */
    margin: 0; /* Hapus margin */
    min-width: auto; /* Pastikan tombol tidak memiliki lebar minimum tetap */
    transform: scale(1); /* Pastikan transformasi tidak mempengaruhi ukuran */
}

.d-flex {
    display: flex; /* Menggunakan Flexbox untuk tata letak */
    align-items: center; /* Vertikal alignment konten */
    flex-wrap: wrap; /* Membungkus tombol jika ruang tidak cukup */
}

.base-button + .base-button {
    margin-left: 4px; /* Ruang antar tombol jika diperlukan */
}

.value-table-wrapped {
   word-wrap: break-word; 
   word-break: break-word; 
   overflow-wrap: break-word; 
   white-space: normal;
}

.btn-swal-blue {
    background-color: #007BFF; /* Biru */
    color: #fff;
    border: none;
}

.btn-swal-blue:hover {
    background-color: #0056b3; /* Biru lebih gelap saat hover */
}

.btn-swal-red {
    background-color: #FF4136; /* Merah */
    color: #fff;
    border: none;
}

.btn-swal-red:hover {
    background-color: #cc3229; /* Merah lebih gelap saat hover */
}

/* Media query untuk ukuran font responsif jika diperlukan */
@media (max-width: 768px) {
  .base-button {
      font-size: 0.75rem; /* Ukuran font lebih kecil pada layar lebih kecil */
      padding: 4px 8px; /* Padding yang lebih kecil */
  }

  .matrix-table {
    font-size: 8px; /* Ukuran font lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    padding: 4px; /* Padding lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    white-space: nowrap; /* Mencegah teks membungkus pada ukuran kecil */
  }
}



</style>